



.menu{
    display: none;
}


@media(max-width: 660px){
    .menu{
        display: block;
    }
}


.offcanvas-body h6{
    padding: 0;
    margin: 0;
    color: rgb(206, 206, 206);
    text-transform: uppercase;
    text-align: start;
    transition: 250ms ease-in-out;
}

.offcanvas-body h6:hover{
    color: rgb(160, 212, 233);
}

.offcanvas-body ul{
    display: flex;
    align-items: start;
    justify-content: center;
}