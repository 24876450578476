
.sombra{
    
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 12); /* Sombra difusa */
}


.card{
    border: none, !important;
}

.container{
    display: flex;
    justify-content: center;
}
@media(max-width: 580px){
    .container{
        width: 90%;
    }
}




.ancho-maximo{
    max-width: 75%;
    display: flex;
    justify-content: center;
}


@media(max-width: 990px){
    .ancho-maximo{
        max-width: 100%;
    }
}




.card-text {
    display: -webkit-box;              /* Establece el contenedor como un box flexible */
    -webkit-line-clamp: 3;             /* Limita el texto a 3 líneas */
    -webkit-box-orient: vertical;      /* Asegura que la orientación sea vertical */
    overflow: hidden;                  /* Oculta el texto que excede el límite */
    text-overflow: ellipsis;           /* Muestra "..." al final del texto truncado */
}



.tarjeta{
    width: 100%;
    height: 150px;
}



.tarjeta-youtube{
    width: 100%;
    height: 300px;
}

@media(max-width: 580px){
    .tarjeta{
        width: 100%;
        height: auto;
    }
    .tarjeta-youtube{
        width: 100%;
        height: 200px;
    }
}
  
  